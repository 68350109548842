import {
  Alert,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  createTheme,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import CollapsibleCard from "../users/member/collapsibleCard";
import MDBox from "components/MDBox";
import flameIcon from "assets/images/fire.png";
import greenArrowIcon from "assets/images/green-arrow.png";
import blueArrowIcon from "assets/images/blue-arrow.png";
import { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { getMemberById } from "api_services/MemberService";
import MDButton from "components/MDButton";
import { getPotentialPartners } from "api_services/MemberService";
import { updateMemberById } from "api_services/MemberService";
import { updatePartnerPriorityById } from "api_services/MemberService";
import FavouriteIcon from "common/Favourite";
import { addFavouriteMarker } from "layouts/dashboard";
import MDInput from "components/MDInput";
import { Link } from "react-router-dom";
import { GENERAL_CONSTS } from "utils/constants/general.constants";
import Footer from "common/Footer";
import { Clear } from "@mui/icons-material";
import { ThemeProvider } from "@emotion/react";
import { updateMarketsOfMultipleMembers } from "api_services/MemberService";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#044B99",
      darker: "#044B99",
    },
  },
});

function PotentialPartnersMemberView() {
  const userCompanies = JSON.parse(secureLocalStorage.getItem("linked-user-companies"));
  const userFavourites = JSON.parse(secureLocalStorage.getItem("user-favourites"));
  const userToken = secureLocalStorage.getItem("custom-token");
  const userCompanyIds = JSON.parse(secureLocalStorage.getItem("current-user-data"))["companies"];
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    userCompanyIds.length > 0 ? userCompanyIds[0]["id"] : ""
  );
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [marketsToDevelop, setMarketsToDevelop] = useState([]);
  const [marketsToDevelopAllForOffices, setMarketsToDevelopForAllOffices] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [partners, setPartners] = useState();
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(false);
  const [allCompaniesSelected, setAllCompaniesSelected] = useState(false);
  const [positionState, setPositionState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = positionState;
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const paginate = (step) => {
    const pageNum = page + step;
    if (pageNum >= 0) {
      setPage(pageNum);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getPartnerList();
  };

  async function updatePartnerPriority(partnerDocId, priority) {
    const response = await updatePartnerPriorityById(partnerDocId, priority, userToken);
    if (response == "ok") {
      setLoadingOpen(false);
      getSelectedMemberDataAndPartners(true);
    } else {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
    }
  }

  async function updateMemberData(memberObj, partnerData) {
    const response = await updateMemberById(selectedCompanyId, memberObj, userToken);
    if (response == "ok") {
      if (partnerData !== null) {
        updatePartnerPriority(partnerData.id, partnerData.priority);
      } else {
        setLoadingOpen(false);
        getSelectedMemberDataAndPartners(true);
      }
      setSelectedCountry(null);
    } else {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
    }
  }

  async function updateMultipleMemberData(dataObj) {
    const response = await updateMarketsOfMultipleMembers(dataObj, userToken);
    if (response == "ok") {
      setLoadingOpen(false);
      setSelectedCountry(null);
      getSelectedMemberDataAndPartners(false);
    } else {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
    }
  }

  const handleMarketChange = (value, toRemove) => {
    setLoadingOpen(true);
    setLoading(true);
    if (allCompaniesSelected) {
      let countries = marketsToDevelopAllForOffices;
      if (toRemove) {
        countries = countries.filter((c) => c !== value);
      } else {
        countries.push(value);
      }
      let dataObj = {
        memberIds: userCompanies.map((i) => i.id),
        markets: countries,
      };
      updateMultipleMemberData(dataObj);
    } else {
      let countries = marketsToDevelop;
      if (toRemove) {
        countries = countries.filter((c) => c !== value);
      } else {
        countries.push(value);
      }
      let memberObj = {
        ...selectedCompany,
        marketsToDevelop: countries,
        airlines: selectedCompany.airlines.map((i) => i.id),
        carriers: selectedCompany.carriers.map((i) => i.id),
        services: selectedCompany.services.map((i) => i.id),
      };
      updateMemberData(memberObj, null);
    }
  };

  async function getPartnerList() {
    setLoading(true);
    const response = await getPotentialPartners(userToken, page, selectedCompanyId, 50, searchData);
    if (response.id !== null) {
      const members = response.searchResults;
      addFavouriteMarker(members, userFavourites, "memberId");
      setPartners(members);
      setNextPage(response.morePages);
      setLoading(false);
    } else {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
    }
  }

  async function getSelectedMemberDataAndPartners(clearCountriesForAllCompanies) {
    if (clearCountriesForAllCompanies) {
      setMarketsToDevelopForAllOffices([]);
    }
    const response = await getMemberById(selectedCompanyId, userToken);
    if (response.id !== null) {
      setSelectedCompany(response);
      setMarketsToDevelop(response.marketsToDevelop);
      getPartnerList();
    } else {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
    }
  }

  async function updateDeprioritizedList(partnerId, partnerDocId, matchCategory) {
    setLoadingOpen(true);
    setLoading(true);
    let lowPriorityPartners = selectedCompany.deprioritizedPotentialPartners;
    let priority;
    if (lowPriorityPartners.includes(partnerId)) {
      lowPriorityPartners = lowPriorityPartners.filter((pid) => pid !== partnerId);
      priority =
        matchCategory === "mutual_interest" ? 1 : matchCategory === "member_interest" ? 2 : 3;
    } else {
      lowPriorityPartners.push(partnerId);
      priority = 4;
    }
    let memberObj = {
      ...selectedCompany,
      deprioritizedPotentialPartners: lowPriorityPartners,
      airlines: selectedCompany.airlines.map((i) => i.id),
      carriers: selectedCompany.carriers.map((i) => i.id),
      services: selectedCompany.services.map((i) => i.id),
    };
    updateMemberData(memberObj, { id: partnerDocId, priority: priority });
  }

  const handleAlertClose = () => {
    setPositionState({ ...positionState, open: false });
  };

  const renderLinkedCompanyList = () => {
    return userCompanies.map((data, index) => {
      const selected = selectedCompanyId === data.id;
      return (
        <MDBox
          id={index}
          borderRadius="lg"
          mr={1}
          mb={1}
          sx={{
            cursor: "pointer",
            // backgroundColor: selected && !allCompaniesSelected ? "#1B2D0A" : "transparent",
            backgroundColor: selected && !allCompaniesSelected ? "#044B99" : "transparent",
            border:
              selected && !allCompaniesSelected ? "1px solid transparent" : "1px solid lightGrey",
            transition: "background-color 300ms ease, border 300ms ease",
          }}
          onClick={() => {
            setAllCompaniesSelected(false);
            setSelectedCompanyId(data.id);
          }}
        >
          <MDTypography
            variant="h6"
            color={selected && !allCompaniesSelected ? "white" : "black"}
            fontWeight="regular"
            py={1}
            px={2}
          >
            {data.companyName} - {data.city}
          </MDTypography>
        </MDBox>
      );
    });
  };

  const renderPartners = partners?.map(
    ({ id, companyName, matchCategory, address, isFavourite, memberId, priority }) => (
      <TableRow key={id}>
        <TableCell>
          <img
            height="20px"
            src={
              matchCategory === "mutual_interest"
                ? flameIcon
                : matchCategory === "partner_interest"
                  ? blueArrowIcon
                  : matchCategory === "member_interest"
                    ? greenArrowIcon
                    : ""
            }
            title={
              matchCategory === "mutual_interest"
                ? "Perfect Match"
                : matchCategory === "partner_interest"
                  ? "Have Shown Interest in Your Location"
                  : matchCategory === "member_interest"
                    ? "Located in a Region You Are Interested In"
                    : ""
            }
            alt="Partner Interest icon"
            style={{ cursor: "help" }}
          />
        </TableCell>
        <TableCell>
          <FavouriteIcon isFavourite={isFavourite} memberId={memberId} refresh={false} />
        </TableCell>
        <TableCell>
          <MDBox>
            <MDTypography
              variant="button"
              fontWeight="small"
              color={priority === 4 ? "grey[700]" : "black"}
            >
              {companyName}
            </MDTypography>
          </MDBox>
        </TableCell>
        <TableCell>
          <MDBox>
            <MDTypography
              variant="button"
              fontWeight="small"
              color={priority === 4 ? "grey[700]" : "black"}
            >
              {address.length > 0 ? address[0].country : ""}
            </MDTypography>
          </MDBox>
        </TableCell>
        <TableCell>
          <MDBox>
            <MDTypography
              variant="button"
              fontWeight="small"
              color={priority === 4 ? "grey[700]" : "black"}
            >
              {address.length > 0 ? address[0].city : ""}
            </MDTypography>
          </MDBox>
        </TableCell>
        <TableCell>
          <MDBox display="flex" justifyContent="center">
            <MDBox
              borderRadius="lg"
              py={1}
              px={2}
              sx={{
                cursor: "pointer",
                backgroundColor: priority === 4 ? "transparent" : "#044B99",
                width: "fit-content",
                textAlign: "center",
              }}
            >
              <MDTypography
                variant="h6"
                color={priority === 4 ? "lightGray" : "white"}
                fontWeight="regular"
                component={Link}
                to={`/directory/profile/${memberId}`}
                target="_blank"
              >
                View Profile
              </MDTypography>
            </MDBox>
          </MDBox>
        </TableCell>
        <TableCell align="center">
          <Switch
            checked={priority === 4}
            onChange={() => updateDeprioritizedList(memberId, id, matchCategory)}
          />
        </TableCell>
      </TableRow>
    )
  );

  useEffect(() => {
    getSelectedMemberDataAndPartners(true);
  }, [selectedCompanyId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchData.length >= 3 || searchData == "") {
        getPartnerList();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [page, searchData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container display="grid" spacing={2}>
        <Grid item>
          <CollapsibleCard
            height="auto"
            title={
              <MDTypography variant="h5" fontWeight="medium" color="black">
                Potential Partners
              </MDTypography>
            }
            canExpand={true}
            initiallyExpanded={true}
          >
            <MDBox display="flex" flexDirection="column">
              <MDTypography variant="h6" color="black" fontWeight="regular" mb={2}>
                Welcome to Freyt Consol's Potential Partner Program. This program is designed to
                simplify the process of identifying business opportunities within our network.{"\n"}
                Here, you can view companies interested in developing business in your region and
                explore companies located in the regions you are interested in.
              </MDTypography>
              <MDTypography variant="h6" color="black" fontWeight="regular" mb={2}>
                We have also introduced a feature that highlights perfect matches, where both
                members select the same region. These matches are clearly marked with a key symbol
                next to the company name. Additionally, you can filter results, and add or remove
                regions of interest to refine your search.
              </MDTypography>
              <MDTypography variant="h6" color="black" fontWeight="regular" mb={2}>
                This tool aims to facilitate seamless connections and foster collaboration among our
                members.
              </MDTypography>
              <MDBox display="flex" alignItems="center" mb={2}>
                <img
                  height="20px"
                  src={flameIcon}
                  alt="Mutual Interest icon"
                  style={{ marginRight: "16px" }}
                />
                <MDTypography variant="h6" color="black" fontWeight="regular">
                  The flame icon identifies a perfect match, meaning both companies have selected
                  each other's respective regions. This indicates a mutual interest in developing
                  business opportunities in the same area, making it an ideal partnership potential.
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center" mb={2}>
                <img
                  height="20px"
                  src={greenArrowIcon}
                  alt="Member Interest icon"
                  style={{ marginRight: "16px" }}
                />
                <MDTypography variant="h6" color="black" fontWeight="regular">
                  The green arrow icon represents a list of all companies located in the regions you
                  have selected.
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center" mb={2}>
                <img
                  height="20px"
                  src={blueArrowIcon}
                  alt="Partner Interest icon"
                  style={{ marginRight: "16px" }}
                />
                <MDTypography variant="h6" color="black" fontWeight="regular">
                  The blue icon represents all companies that have shown interest in your
                  location(s).
                </MDTypography>
              </MDBox>
            </MDBox>
          </CollapsibleCard>
        </Grid>
        <Grid
          item
          sx={{
            maxWidth: `calc(100vw - 16px)`,
          }}
        >
          <Card sx={{ mb: 5, p: 3 }}>
            <MDBox display="flex" flexDirection="column" mb={2}>
              <MDBox display="flex" alignItems="center" mb={1}>
                <Icon fontSize="large" color="primary" sx={{ marginRight: "10px" }}>
                  domain
                </Icon>
                <MDTypography variant="h5" color="black" fontWeight="regular">
                  Step 1
                </MDTypography>
              </MDBox>
              <MDTypography variant="h6" color="black" fontWeight="regular">
                Before we get started, there are a few things we need to do. Firstly, if you are a
                contact for multiple offices, you will need to select which office you are picking
                the locations for, as each office has its own Partner Program. If you are only a
                contact for a single office, you can move on to the next step. Please note that when
                selecting regions for an office, this selection will be the same for all contacts
                associated with the respective office.
              </MDTypography>
            </MDBox>
            <MDBox
              mb={1}
              display="flex"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                maxWidth: "3000px",
                flexWrap: "wrap",
              }}
            >
              {renderLinkedCompanyList()}
            </MDBox>
            <MDBox display="flex" flexDirection="column" mb={2}>
              <MDBox display="flex" alignItems="center" mb={1}>
                <Icon fontSize="large" color="primary" sx={{ marginRight: "10px" }}>
                  language
                </Icon>
                <MDTypography variant="h5" color="black" fontWeight="regular">
                  Step 2
                </MDTypography>
              </MDBox>
              <MDTypography variant="h6" color="black" fontWeight="regular">
                Now it's time to choose which locations are of interest to you. You can select as
                many as you like, and you can also easily remove countries by clicking on the "X"
                next to the country name below. Remember, the countries you select or remove here
                will reflect the same for all contacts associated with this office.
              </MDTypography>
            </MDBox>
            {allCompaniesSelected ? (
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={1.8} xl={1.8}>
                  <MDTypography variant="h6" color="black" fontWeight="regular" pl={0.5}>
                    Selected Countries :
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={12} md={10.2} xl={10.2}>
                  <MDBox
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      overflowX: "auto",
                      minHeight: 53,
                      gap: 1,
                      color: "white",
                    }}
                  >
                    {marketsToDevelopAllForOffices.map((value) => (
                      <Chip
                        sx={{ backgroundColor: "lightgray" }}
                        onDelete={() => {
                          handleMarketChange(value, true);
                        }}
                        key={value}
                        label={
                          <MDTypography variant="button" color="gray">
                            {value}
                          </MDTypography>
                        }
                        deleteIcon={<Icon fontSize="medium">cancel</Icon>}
                      />
                    ))}
                  </MDBox>
                </Grid>
              </Grid>
            ) : marketsToDevelop.length > 0 ? (
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={1.8} xl={1.8}>
                  <MDTypography variant="h6" color="black" fontWeight="regular" pl={0.5}>
                    Selected Countries :
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={12} md={10.2} xl={10.2}>
                  <MDBox
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      overflowX: "auto",
                      minHeight: 53,
                      gap: 1,
                      color: "white",
                    }}
                  >
                    {marketsToDevelop.map((value) => (
                      <Chip
                        sx={{ backgroundColor: "lightgray" }}
                        onDelete={() => {
                          handleMarketChange(value, true);
                        }}
                        key={value}
                        label={
                          <MDTypography variant="button" color="gray">
                            {value}
                          </MDTypography>
                        }
                        deleteIcon={<Icon fontSize="medium">cancel</Icon>}
                      />
                    ))}
                  </MDBox>
                </Grid>
              </Grid>
            ) : null}
            <MDBox mt={2} mb={0.7}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">
                  Select The Countries You Are Interested In
                </InputLabel>
                <Select
                  value={selectedCountry}
                  endAdornment={
                    selectedCountry !== null ? (
                      <IconButton onClick={() => setSelectedCountry(null)}>
                        <Clear />
                      </IconButton>
                    ) : null
                  }
                  label={"Select The Countries You Are Interested In"}
                  id="label"
                  labelId="demo-multiple-name-label"
                  fullWidth
                  onChange={(e) => {
                    setSelectedCountry(e.target.value);
                    handleMarketChange(e.target.value, false);
                  }}
                  MenuProps={MenuProps}
                  sx={{ minHeight: 43, borderColor: "red" }}
                >
                  {GENERAL_CONSTS.COUNTRIES.map((item, idx) => (
                    <MenuItem
                      sx={{
                        my: 1,
                        borderRadius: 50,
                        width: "min-content",
                        color: "info",
                      }}
                      key={idx}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MDBox>
            <MDBox mb={1}>
              <ThemeProvider theme={theme}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="allCompaniesSelected"
                        checked={allCompaniesSelected}
                        onChange={(e) => {
                          setAllCompaniesSelected(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="black"
                        width="max-content"
                      >
                        <span style={{ color: "black", fontSize: "11.2px" }}>
                          Add these countries for all offices
                        </span>
                      </MDTypography>
                    }
                  />
                </FormGroup>
              </ThemeProvider>
            </MDBox>
            {!allCompaniesSelected ? (
              <>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  component="form"
                  role="form"
                  onSubmit={handleSubmit}
                >
                  <MDBox
                    sx={{
                      pb: 2.5,
                      pt: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <MDInput
                      sx={{ mr: 2, pl: 0, flex: 1 }}
                      placeholder="Search partners by : Company Name | Country | City"
                      onChange={(e) => setSearchData(e.target.value)}
                    />
                    <MDButton size="medium" color="primary" type="submit">
                      Search
                    </MDButton>
                  </MDBox>
                  {/* {searchData.length < 3 ? null : (
            <MDBox py={2.5} px={2.5} sx={{ borderTop: "1px solid #d7d9e1" }}>
              <MDBox sx={{ display: "flex", flexWrap: "wrap", gap: 1, color: "white" }}>
                {filters.map((item) => (
                  <Chip
                    sx={{
                      border: selectedFilters.includes(item.value)
                        ? "1px solid transparent"
                        : "1px solid white",
                      background: selectedFilters.includes(item.value)
                        ? "#044B99 !important"
                        : "auto",
                    }}
                    key={item.value}
                    label={
                      <MDTypography
                        variant="button"
                        color={selectedFilters.includes(item.value) ? "secondnary" : "black"}
                      >
                        {item.title}
                      </MDTypography>
                    }
                    onClick={() => addFilter(item.value)}
                  />
                ))}
              </MDBox>
            </MDBox>
          )} */}
                </MDBox>
                {loading ? (
                  <MDBox display="flex" justifyContent="center">
                    <CircularProgress />
                  </MDBox>
                ) : (
                  <>
                    {partners?.length !== 0 ? (
                      <MDBox
                        py={1}
                        style={{
                          maxWidth: `calc(100vw - 16px)`,
                        }}
                      >
                        <TableContainer fullWidth sx={{ px: 2, boxShadow: "0px 0px 0px white" }}>
                          <Table sx={{ minWidth: 800 }} aria-label="simple table" fullWidth>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <MDTypography variant="button" color="transparent">
                                    -
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="button" color="text">
                                    Favourite
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="button" color="text">
                                    Company Name
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="button" color="text">
                                    Country
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="button" color="text">
                                    City
                                  </MDTypography>
                                </TableCell>
                                <TableCell>
                                  <MDTypography variant="button" color="transparent">
                                    -
                                  </MDTypography>
                                </TableCell>
                                <TableCell align="center">
                                  <MDTypography variant="button" color="text">
                                    Hide
                                  </MDTypography>
                                </TableCell>
                              </TableRow>
                              {renderPartners}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <MDBox display="flex" justifyContent="end" alignItems="center" mt={3}>
                          {page > 0 ? (
                            <MDButton
                              onClick={() => {
                                if (page > 0) {
                                  paginate(-1);
                                }
                              }}
                              variant="text"
                              size="small"
                              color="primary"
                            >
                              Previous Page
                            </MDButton>
                          ) : null}
                          {page > 0 && nextPage ? (
                            <MDTypography variant="button" color="primary">
                              |
                            </MDTypography>
                          ) : null}
                          {nextPage ? (
                            <MDButton
                              onClick={() => {
                                if (nextPage) {
                                  paginate(1);
                                }
                              }}
                              variant="text"
                              size="small"
                              color="primary"
                            >
                              Next Page
                            </MDButton>
                          ) : null}
                        </MDBox>
                      </MDBox>
                    ) : (
                      <MDTypography
                        variant="text"
                        color="text"
                        display="flex"
                        textAlign="center"
                        justifyContent="center"
                        mb={2}
                      >
                        No Potential Partners found
                      </MDTypography>
                    )}
                  </>
                )}
              </>
            ) : null}
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          Failed to load potential partners for this company!
        </Alert>
      </Snackbar>

      <MDBox sx={{ overflowY: "visible" }}>
        <Dialog
          open={loadingOpen}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflowY: "visible" }}
        >
          <MDBox mb={1} mt={2} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
            <MDTypography display="block" variant="button" color="primaryDark">
              Updating Potential Partners ...
            </MDTypography>
            {loadingOpen && <CircularProgress sx={{ mt: 2 }} />}
          </MDBox>
        </Dialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PotentialPartnersMemberView;
