import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";
import { Helmet, HelmetProvider } from "react-helmet-async";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta
          name="description"
          content="THINK GLOBAL. CONNECT LOCAL - Embrace the power of connectivity and discover the power of collaboration with Freyt Consol."
        />
        <meta property="og:title" content="Freyt Consol" />
        <meta
          property="og:description"
          content="THINK GLOBAL. CONNECT LOCAL - Embrace the power of connectivity and discover the power of collaboration with Freyt Consol."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://portal.freytconsol.com" />
        <meta
          property="og:image"
          content="https://portal.freytconsol.com/static/media/fw-logo-ct-darkback.7fec2456c65af9fdc750.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="620" />
        <meta property="og:image:height" content="548" />
        <meta property="og:image:alt" content="Freyt Consol" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Freyt Consol" />
      </Helmet>
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",

          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(96) : pxToRem(219.2),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </MDBox>
    </HelmetProvider>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
