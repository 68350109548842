import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import { Button, Menu } from "@mui/material";
import NotificationItem from "common/Items/NotificationItem";
import LanguageIcon from "@mui/icons-material/Language";
import PublicIcon from "@mui/icons-material/Public";
import { useTranslation } from "react-i18next";
import { useMaterialUIController, setDirection } from "context";

function Footer({ light, longContent }) {
  const [, dispatch] = useMaterialUIController();
  const { size } = typography;
  const currentYear = new Date().getFullYear();

  const { t, i18n } = useTranslation();
  function handleLanguage(lang) {
    i18n.changeLanguage(lang);
    handleCloseMenuLang();
    changeDirectionBack();
  }

  function handleLanguageARA(lang) {
    i18n.changeLanguage(lang);
    handleCloseMenuLang();
    changeDirection();
  }

  // Changing the direction to rtl
  const changeDirection = () => {
    setDirection(dispatch, "rtl");

    return () => setDirection(dispatch, "ltr");
  };

  const changeDirectionBack = () => {
    setDirection(dispatch, "ltr");

    return () => setDirection(dispatch, "rtl");
  };

  const [openMenuLang, setOpenMenuLang] = useState(false);

  const handleOpenMenuLang = (event) => setOpenMenuLang(event.currentTarget);
  const handleCloseMenuLang = () => setOpenMenuLang(false);

  const renderMenuLang = () => (
    <Menu
      anchorEl={openMenuLang}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenuLang)}
      onClose={handleCloseMenuLang}
      sx={{ mt: -7 }}
    >
      <MDBox sx={{ borderBottom: "1px solid rgb(0, 0, 0, 0.2)" }}>
        <MDTypography>
          <Button
            variant="none"
            color="text"
            startIcon={<PublicIcon />}
            onClick={() => handleLanguage("eng")}
          >
            ENG
          </Button>
        </MDTypography>
      </MDBox>
      <MDBox sx={{ borderBottom: "1px solid rgb(0, 0, 0, 0.2)" }}>
        <MDTypography>
          <Button
            variant="none"
            color="text"
            startIcon={<PublicIcon />}
            onClick={() => handleLanguage("ger")}
          >
            DEU
          </Button>
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography>
          <Button
            variant="none"
            color="text"
            startIcon={<PublicIcon />}
            onClick={() => handleLanguageARA("ara")}
          >
            عربي
          </Button>
        </MDTypography>
      </MDBox>
    </Menu>
  );

  return (
    <MDBox width="100%" bottom={0} pt={2} data-testid="footer">
      <Container>
        <MDBox
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
          px={1}
          mt={10}
        >
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            color={light ? "white" : "text"}
            fontSize={size.sm}
          >
            &copy; {currentYear}, Freyt Consol Pte Ltd
          </MDBox>
          <MDBox
            component="ul"
            sx={({ breakpoints }) => ({
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              listStyle: "none",
              mt: 3,
              mb: 0,
              p: 0,

              [breakpoints.up("lg")]: {
                mt: 0,
              },
            })}
          >
            <MDBox component="li" px={2} lineHeight={1}>
              <Link href="https://freytconsol.com/about-us" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={light ? "white" : "dark"}
                >
                  {t("footer.aboutus")}
                </MDTypography>
              </Link>
            </MDBox>
            <MDBox component="li" px={2} lineHeight={1}>
              <Link href="https://freytconsol.com/contact-us" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={light ? "white" : "dark"}
                >
                  {t("footer.contact")}
                </MDTypography>
              </Link>
            </MDBox>
            {/* <MDBox component="li" pl={2} lineHeight={1}>
              <Link href="" target="_blank">
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color={light ? "white" : "dark"}
                  pr={3}
                >
                  {t("footer.license")}
                </MDTypography>
              </Link>
            </MDBox> */}
            <MDTypography color={light ? "white" : "dark"} display="flex" alignItems="center">
              <LanguageIcon
                sx={{ cursor: "pointer" }}
                fontSize="medium"
                onClick={handleOpenMenuLang}
              />
            </MDTypography>
            {renderMenuLang()}
          </MDBox>
        </MDBox>
      </Container>
    </MDBox>
  );
}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
