import * as React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Alert, Card, CircularProgress, Dialog, Snackbar } from "@mui/material";
import MDButton from "components/MDButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MDAvatar from "components/MDAvatar";
import { useState } from "react";
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import secureLocalStorage from "react-secure-storage";
import { Link } from "react-router-dom";
import { BorderColor, InfoRounded } from "@mui/icons-material";
import { getUserUpdateRequests } from "api_services/UserService";
import { updateUserByID } from "api_services/UserService";
import { updateUserUpdateRequestStatus } from "api_services/UserService";

function getCurrentDate() {
  let date = new Date();
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0");
  let yyyy = String(date.getFullYear());
  return yyyy + "-" + mm + "-" + dd;
  // return a date in the form yyyy-mm-dd
}

function UpdateRequests() {
  // const [searchData, setSearchData] = useState("");
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allRequests, setAllRequests] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [reqId, setReqId] = useState();
  const [reqData, setReqData] = useState();
  const [accepting, setAccepting] = useState(false);
  const userToken = secureLocalStorage.getItem("custom-token");

  const [positionState, setPositionState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    msg: "",
    color: "",
  });
  const { vertical, horizontal, open, msg, color } = positionState;
  const handleAlertClose = () => {
    setPositionState({ ...positionState, open: false });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = (id, data) => {
    setReqId(id);
    setReqData(data);
    setOpenDialog(true);
  };

  const approveProfileChanges = async (id, updatedData) => {
    try {
      setAccepting(true);
      const response_db = await updateUserByID(id, userToken, updatedData);
      const response = await updateUserUpdateRequestStatus(userToken, id, "false");
      if (response_db == "ok") {
        //console.log("updated DB Successfully");
        setAccepting(false);
        setPositionState({
          open: true,
          vertical: "top",
          horizontal: "center",
          msg: " User profile updated successfully !",
          color: "success",
        });
        handleClose();
        getAllUserUpdateRequests();
      } else {
        setAccepting(false);

        setPositionState({
          open: true,
          vertical: "top",
          horizontal: "center",
          msg: " Failed to update user profile !",
          color: "error",
        });
        //console.log("update failed");
      }
    } catch (e) {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
    }
  };

  async function getAllUserUpdateRequests() {
    const response = await getUserUpdateRequests(userToken, page);

    if (response !== null) {
      setIsLoading(false);
      setAllRequests(response.searchResults);
      setNextPage(response.morePages);
      //console.log(response);
      //console.log("All Requests", response.searchResults);
    } else {
      setIsLoading(true);
      //console.log(response);
    }
  }
  const paginate = (step) => {
    const pageNum = page + step;
    if (pageNum >= 0) {
      setPage(pageNum);
    }
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (searchData.length >= 3 || searchData == "") {
  //       getAllUserUpdateRequests();
  //     }
  //   }, 500);

  //   return () => clearTimeout(timer);
  // }, [page, searchData]);

  useEffect(() => {
    getAllUserUpdateRequests();
  }, []);

  const renderUsers = allRequests.map(
    ({ id, displayName, avatar, linkedinUrl, phoneNumber, title, requestTimestamp }) => (
      <TableRow key={id}>
        <TableCell>
          {avatar.isUpdated ? (
            <MDBox display="flex">
              <MDAvatar
                src={!avatar.oldValue ? "./placeholder.jpg" : avatar.oldValue}
                name={displayName.oldValue}
                size="md"
                variant="square"
                sx={{ borderRadius: 3, border: "3px solid red", opacity: "0.5" }}
              />
              <MDBox px={0.3} />
              <MDAvatar
                src={!avatar.newValue ? "./placeholder.jpg" : avatar.newValue}
                name={displayName.newValue}
                size="md"
                variant="square"
                sx={{ borderRadius: 3, border: "3px solid #A1FFAE" }}
              />
            </MDBox>
          ) : (
            <MDAvatar
              src={!avatar.newValue ? "./placeholder.jpg" : avatar.newValue}
              name={displayName.newValue}
              size="md"
              variant="square"
              sx={{ borderRadius: 3 }}
            />
          )}
        </TableCell>
        <TableCell>
          <MDTypography display="block" variant="button" fontWeight="medium" color="black">
            {displayName.isUpdated ? (
              <MDBox display="flex" flexDirection="column">
                <MDTypography
                  py={0.2}
                  px={0.5}
                  mb={0.5}
                  sx={{ backgroundColor: "#DAFBE1", width: "fit-content" }}
                  variant="button"
                  fontWeight="small"
                  color="black"
                >
                  {displayName.newValue}
                </MDTypography>
                <MDTypography
                  p={0.2}
                  px={0.5}
                  sx={{ backgroundColor: "#FFEBE9", width: "fit-content" }}
                  variant="button"
                  fontWeight="small"
                  color="black"
                >
                  {displayName.oldValue}
                </MDTypography>
              </MDBox>
            ) : (
              <MDTypography variant="button" fontWeight="small" color="black">
                {displayName.newValue}
              </MDTypography>
            )}
          </MDTypography>
        </TableCell>
        <TableCell>
          <MDTypography variant="button" fontWeight="small" color="black">
            {title.isUpdated ? (
              <MDBox display="flex" flexDirection="column">
                <MDTypography
                  py={0.2}
                  px={0.5}
                  mb={0.5}
                  sx={{ backgroundColor: "#DAFBE1", width: "fit-content" }}
                  variant="button"
                  fontWeight="small"
                  color="black"
                >
                  {title.newValue}
                </MDTypography>
                <MDTypography
                  p={0.2}
                  px={0.5}
                  sx={{ backgroundColor: "#FFEBE9", width: "fit-content" }}
                  variant="button"
                  fontWeight="small"
                  color="black"
                >
                  {title.oldValue}
                </MDTypography>
              </MDBox>
            ) : (
              <MDTypography variant="button" fontWeight="small" color="black">
                {title.newValue}
              </MDTypography>
            )}
          </MDTypography>
        </TableCell>
        <TableCell>
          <MDTypography variant="button" fontWeight="small" color="black">
            {phoneNumber.isUpdated ? (
              <MDBox display="flex" flexDirection="column">
                <MDTypography
                  py={0.2}
                  px={0.5}
                  mb={0.5}
                  sx={{ backgroundColor: "#DAFBE1", width: "fit-content" }}
                  variant="button"
                  fontWeight="small"
                  color="black"
                >
                  {phoneNumber.newValue}
                </MDTypography>
                <MDTypography
                  p={0.2}
                  px={0.5}
                  sx={{ backgroundColor: "#FFEBE9", width: "fit-content" }}
                  variant="button"
                  fontWeight="small"
                  color="black"
                >
                  {phoneNumber.oldValue}
                </MDTypography>
              </MDBox>
            ) : (
              <MDTypography variant="button" fontWeight="small" color="black">
                {phoneNumber.newValue}
              </MDTypography>
            )}
          </MDTypography>
        </TableCell>
        <TableCell>
          {linkedinUrl.isUpdated ? (
            <MDBox display="flex" flexDirection="column">
              <MDTypography
                py={0.2}
                px={0.5}
                mb={0.5}
                sx={{ backgroundColor: "#DAFBE1", width: "fit-content" }}
                variant="button"
                fontWeight="small"
                color="black"
              >
                {linkedinUrl.newValue}
              </MDTypography>
              <MDTypography
                p={0.2}
                px={0.5}
                sx={{ backgroundColor: "#FFEBE9", width: "fit-content" }}
                variant="button"
                fontWeight="small"
                color="black"
              >
                {linkedinUrl.oldValue}
              </MDTypography>
            </MDBox>
          ) : (
            <MDTypography variant="button" fontWeight="small" color="black">
              {linkedinUrl.newValue}
            </MDTypography>
          )}
        </TableCell>
        <TableCell>
          <MDBox display="flex" alignItems="center">
            <MDBox component={Link} to={`/update_requests/${id}`}>
              <MDTypography
                variant="button"
                color="black"
                fontWeight="small"
                sx={{ cursor: "pointer", opacity: 0.7 }}
                title="View and edit request"
              >
                <BorderColor fontSize="small" />
              </MDTypography>
            </MDBox>
            <MDBox px={0.7} />
            <MDTypography
              variant="button"
              color="primary"
              fontWeight="medium"
              sx={{ cursor: "pointer" }}
              title="Approve request"
              onClick={() =>
                handleOpen(
                  id,
                  phoneNumber.isUpdated
                    ? {
                        displayName: displayName.newValue,
                        title: title.newValue,
                        phoneNumber: phoneNumber.newValue,
                        linkedinUrl: linkedinUrl.newValue,
                        hasWhatsapp: false,
                      }
                    : {
                        displayName: displayName.newValue,
                        title: title.newValue,
                        phoneNumber: phoneNumber.newValue,
                        linkedinUrl: linkedinUrl.newValue,
                      }
                )
              }
            >
              <CheckCircleIcon fontSize="medium" />
            </MDTypography>
            <MDBox px={0.7} />
            <MDTypography
              variant="button"
              color="black"
              fontWeight="small"
              sx={{ cursor: "help", opacity: 0.5 }}
              title={`Requested on ${new Date(requestTimestamp).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}`}
            >
              <InfoRounded fontSize="small" />
            </MDTypography>
          </MDBox>
        </TableCell>
      </TableRow>
    )
  );

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <Card sx={{ mb: 5, px: 2, py: 3 }}>
          <Grid
            container
            pb={0}
            pl={2}
            lineHeight={1.25}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={12} md={4} xl={4}>
              <MDTypography
                variant="h5"
                fontWeight="medium"
                color="black"
                sx={{ mb: { xs: 0, md: 0 } }}
              >
                Profile Update Requests
              </MDTypography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              xl={8}
              display="flex"
              sx={{ justifyContent: { xs: "start", md: "end" }, mb: { xs: 2, md: 0 } }}
            >
              {/* <Grid container px={2} displa="flex" alignItems="center" justifyContent="end">
                <Grid item xs={12} md={4} xl={4} ml={1} mt={2}>
                  <MDInput
                    label="From"
                    bgColor="white"
                    value={date}
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                    type={"date"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4} xl={4} ml={1} mt={2}>
                  <MDInput
                    label="To"
                    bgColor="white"
                    value={dateTwo}
                    onChange={(e) => {
                      setDateTwo(e.target.value);
                    }}
                    type={"date"}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={1.5}
                  xl={1.5}
                  display="flex"
                  justifyContent="end"
                  ml={2}
                  mt={2}
                >
                  <MDButton size="medium" color="primary" variant="outlined">
                    export
                  </MDButton>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
          {/* <MDBox
            component="form"
            role="form"
            onSubmit={handleSubmit}
            sx={{
              p: 2,
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <MDInput
              sx={{ mr: 2.5, pl: 0, flex: 1 }}
              onChange={(e) => setSearchData(e.target.value)}
              placeholder="Search for Login Records"
            />
            <MDButton size="medium" color="primary" type="submit">
              Search
            </MDButton>
          </MDBox> */}

          {isLoading ? (
            // Render a spinner or loading indicator
            <Stack spacing={3} sx={{ mt: 3 }}>
              <MDBox display="flex">
                <Skeleton variant="circular" width={120} height={100} sx={{ mr: 3 }} />
                <Skeleton variant="rectangular" width={"100%"} height={100} />
              </MDBox>
              <MDBox display="flex">
                <Skeleton variant="circular" width={120} height={100} sx={{ mr: 3 }} />
                <Skeleton variant="rectangular" width={"100%"} height={100} />
              </MDBox>
              <Skeleton variant="rectangular" width={"100%"} height={70} />
              <Skeleton variant="rectangular" width={"100%"} height={40} />
              <Skeleton variant="rectangular" width={"100%"} height={20} />
            </Stack>
          ) : (
            <>
              {allRequests.length !== 0 ? (
                <Grid container>
                  <Grid item xs={12} md={12} xl={12}>
                    <MDBox py={1} mt={4}>
                      <TableContainer fullWidth sx={{ px: 2, boxShadow: "0px 0px 0px white" }}>
                        <Table sx={{ minWidth: 550 }} aria-label="simple table" fullWidth>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <MDTypography variant="button" color="text">
                                  Photo
                                </MDTypography>
                              </TableCell>
                              <TableCell>
                                <MDTypography variant="button" color="text">
                                  Full Name
                                </MDTypography>
                              </TableCell>
                              <TableCell>
                                <MDTypography variant="button" color="text">
                                  Title
                                </MDTypography>
                              </TableCell>
                              <TableCell>
                                <MDTypography variant="button" color="text">
                                  Contact Number
                                </MDTypography>
                              </TableCell>
                              <TableCell>
                                <MDTypography variant="button" color="text">
                                  LinkedIn URL
                                </MDTypography>
                              </TableCell>
                              <TableCell>
                                <MDTypography variant="button" color="text">
                                  Actions
                                </MDTypography>
                              </TableCell>
                            </TableRow>
                            {renderUsers}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <MDBox display="flex" justifyContent="end" alignItems="center" mt={3}>
                        {page > 0 ? (
                          <MDButton
                            onClick={() => {
                              if (page > 0) {
                                paginate(-1);
                              }
                            }}
                            variant="text"
                            size="small"
                            color="primary"
                          >
                            Previous Page
                          </MDButton>
                        ) : null}
                        {page > 0 && nextPage ? (
                          <MDTypography variant="button" color="primary">
                            |
                          </MDTypography>
                        ) : null}
                        {nextPage ? (
                          <MDButton
                            onClick={() => {
                              if (nextPage) {
                                paginate(1);
                              }
                            }}
                            variant="text"
                            size="small"
                            color="primary"
                          >
                            Next Page
                          </MDButton>
                        ) : null}
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              ) : (
                <Grid my={20}>
                  <MDTypography
                    variant="text"
                    color="text"
                    display="flex"
                    justifyContent="center"
                    mb={2}
                  >
                    No update requests found !
                  </MDTypography>
                </Grid>
              )}
            </>
          )}
        </Card>
        <Footer />
      </DashboardLayout>
      <MDBox sx={{ overflowY: "visible" }}>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflowY: "visible" }}
        >
          <MDBox mb={1} mt={5} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
            <MDTypography display="block" variant="button" color="primaryDark" textAlign="center">
              Are you sure you want to approve these changes?
            </MDTypography>
            {accepting && <CircularProgress sx={{ mt: 2 }} />}
          </MDBox>
          <MDBox mt={2} mb={4} display="flex" justifyContent="center">
            <MDBox mr={2}>
              <MDButton
                onClick={handleClose}
                autoFocus
                variant="outlined"
                color="primary"
                size="small"
              >
                Cancel
              </MDButton>
            </MDBox>
            <MDButton
              autoFocus
              variant="gradient"
              color="primary"
              onClick={() => approveProfileChanges(reqId, reqData)}
              size="small"
            >
              Confirm
            </MDButton>
          </MDBox>
        </Dialog>
      </MDBox>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleAlertClose} severity={color} sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default UpdateRequests;
