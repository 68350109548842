var BASE_URL = process.env.REACT_APP_BASE_URL;

var API_BASE_URL = BASE_URL + "member-service/options";

var TENANT_ID = "FC";

export async function addNewOption(optionDetails, token) {
  try {
    const response = await fetch(API_BASE_URL, {
      method: "POST",

      // Adding body or contents to send
      body: JSON.stringify(optionDetails),

      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function getOptionById(id, token) {
  const response = await fetch(API_BASE_URL + "/" + id, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
  if (response.ok) {
    return response.json();
  } else {
    return response.status(500).send({ error: "Error loading list" });
  }
}

export async function deleteOptionById(id, token) {
  try {
    const response = await fetch(API_BASE_URL + "/" + id, {
      method: "DELETE",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function updateOptionById(id, updatedData, token) {
  try {
    const response = await fetch(API_BASE_URL + "/" + id, {
      method: "PUT",

      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      throw response;
    } else {
      return "ok";
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}

export async function searchOptions(token, page, category, searchText) {
  try {
    const response = await fetch(API_BASE_URL + "/" + category, {
      method: "POST",
      // Adding body or contents to send
      body: JSON.stringify({
        query: {
          textSearch: searchText,
        },
        offset: page,
        limit: 100,
        sortBy: ["name"],
        sortOrder: "asc",
      }),
      // Adding headers to the request
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
        "x-tenantid": TENANT_ID,
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return response.status(500).send({ error: "Error loading list" });
    }
  } catch (error) {
    let responseTxt = await error.text();
    return responseTxt;
  }
}
