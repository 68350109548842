import { Card, CircularProgress, Grid, Skeleton, Stack } from "@mui/material";
import Footer from "common/Footer";
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Header from "../components/Header";
import MDInput from "components/MDInput";
import secureLocalStorage from "react-secure-storage";
import { useEffect, useState } from "react";
import { ROLE_ACCESS } from "utils/constants/roles.constants";
import { getAllMembers } from "api_services/MemberService";
import CompanyCard from "common/Cards/ProjectCards/companyCard";
import { useTranslation } from "react-i18next";
import convertToCSV from "utils/helpers/convertToCSV";
import { exportCompanies } from "api_services/MemberService";

function getCurrentDate() {
  let date = new Date();
  return date.getTime();
}

function PotentialPartnersAdminView() {
  // const filters = [
  //   {
  //     value: "memberContacts.displayName",
  //     title: "Personnel",
  //   },
  //   {
  //     value: "companyName",
  //     title: "Companies",
  //   },
  //   {
  //     value: "exportCountries",
  //     title: "Export Countries",
  //   },
  //   {
  //     value: "importCountries",
  //     title: "Import Countries",
  //   },
  //   {
  //     value: "services.name",
  //     title: "Services",
  //   },
  //   {
  //     value: "carriers.name",
  //     title: "Carriers",
  //   },
  //   {
  //     value: "airlines.name",
  //     title: "Airlines",
  //   },
  //   {
  //     value: "marketsToDevelop",
  //     title: "Markets Looking to Develop In",
  //   },
  // ];
  const [memberData, setMemberData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [nextPage, setNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const userToken = secureLocalStorage.getItem("custom-token");
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    getAllMemberList();
  };

  async function getAllMemberList() {
    const filterList = ["marketsToDevelop"];
    const response = await getAllMembers(searchData, filterList, userToken, true, true, page, 100);
    if (response !== null) {
      const members = response.searchResults;
      setIsLoading(false);
      setMemberData(members);
      setNextPage(response.morePages);
    } else {
      setIsLoading(true);
      //console.log(response);
    }
  }

  async function exportMemberMarketsData() {
    setLoadingExport(true);
    const response = await exportCompanies(userToken, "markets", 0, searchData);

    if (response != null) {
      setLoadingExport(false);
      //Create csv file
      const csvData = convertToCSV(response);
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      const date = new Date(Date.now());
      if (searchData !== "") {
        a.setAttribute(
          "download",
          `FreytConsol Company Data Looking to Develop In ${
            searchData.charAt(0).toUpperCase() + searchData.slice(1)
          } - ${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`
        );
      } else {
        a.setAttribute(
          "download",
          `FreytConsol Company Data Markets Looking to Develop In - ${date.getDate()}-${
            date.getMonth() + 1
          }-${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`
        );
      }
      a.click();
    } else {
      setLoadingExport(false);
    }
  }

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const paginate = (step) => {
    goToTop();
    const pageNum = page + step;
    if (pageNum >= 0) {
      setPage(pageNum);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchData.length >= 3 || searchData == "") {
        getAllMemberList();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [page, searchData]);

  const isExpired = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    return date1.getTime() >= date2.getTime();
  };

  const renderMemberProfiles = memberData.map((data, index) => {
    const currentDate = getCurrentDate();
    const expiryDate = data.expiryDate;
    const expired = isExpired(currentDate, expiryDate);
    return (
      <Grid item sm={12} md={12} lg={12} xl={6} xxl={6} key={data.id} sx={{ width: "100%" }}>
        <CompanyCard
          isEditor={true}
          id={data.id}
          image={data.companyLogo}
          isHeadOffice={data.isHeadOffice == "true" && searchData !== ""}
          label={data.address?.map((add) => add.city + ", " + add.country + "  ")}
          description={data.companyProfile}
          title={data.companyName}
          linkedin={data.linkedInUrl}
          action={{
            type: "internal",
            route: `/directory/profile/${data.id}`,
          }}
          authors={data.memberContacts}
          expired={expired}
          isFavourite={data.isFavourite}
          refresh={false}
        />
      </Grid>
    );
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Card sx={{ marginBottom: 3 }}>
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
          component="form"
          role="form"
          onSubmit={handleSubmit}
        >
          <MDBox
            sx={{
              p: 2.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <MDInput
              sx={{ mr: 2, pl: 0, flex: 1 }}
              placeholder="Search by : Markets Looking To Develop In"
              onChange={(e) => setSearchData(e.target.value)}
            />
            <MDButton size="medium" color="primary" type="submit">
              Search
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      {isLoading ? (
        // Render a spinner or loading indicator
        <Stack spacing={3}>
          <MDBox display="flex">
            <Skeleton variant="circular" width={120} height={100} sx={{ mr: 3 }} />
            <Skeleton variant="rectangular" width={"100%"} height={100} />
          </MDBox>
          <MDBox display="flex">
            <Skeleton variant="circular" width={120} height={100} sx={{ mr: 3 }} />
            <Skeleton variant="rectangular" width={"100%"} height={100} />
          </MDBox>
          <Skeleton variant="rectangular" width={"100%"} height={70} />
          <Skeleton variant="rectangular" width={"100%"} height={40} />
          <Skeleton variant="rectangular" width={"100%"} height={20} />
        </Stack>
      ) : (
        <>
          {memberData.length !== 0 ? (
            <Header>
              <Grid
                container
                pt={0.5}
                pb={2}
                px={0}
                lineHeight={1.25}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={12} sm={12} md={6} xl={7} mb={1} mr={2}>
                  <MDTypography variant="h5" fontWeight="medium" color="black">
                    Companies
                  </MDTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  xl={3.8}
                  display="flex"
                  alignItems="center"
                  sx={{
                    justifyContent: { xs: "start", md: "end" },
                    mt: { xs: 2, md: 0 },
                  }}
                >
                  {loadingExport ? (
                    <CircularProgress sx={{ mr: 2 }} />
                  ) : (
                    <MDButton onClick={exportMemberMarketsData} size="small" color="primary">
                      {t("contacts.export")}
                    </MDButton>
                  )}
                </Grid>
              </Grid>
              <MDBox py={1}>
                <Grid container spacing={3}>
                  {renderMemberProfiles}
                </Grid>
              </MDBox>
              <MDBox display="flex" justifyContent="end" alignItems="center" mt={3}>
                {page > 0 ? (
                  <MDButton
                    onClick={() => {
                      if (page > 0) {
                        paginate(-1);
                      }
                    }}
                    variant="text"
                    size="small"
                    color="primary"
                  >
                    Previous Page
                  </MDButton>
                ) : null}
                {page > 0 && nextPage ? (
                  <MDTypography variant="button" color="primary">
                    |
                  </MDTypography>
                ) : null}
                {nextPage ? (
                  <MDButton
                    onClick={() => {
                      if (nextPage) {
                        paginate(1);
                      }
                    }}
                    variant="text"
                    size="small"
                    color="primary"
                  >
                    Next Page
                  </MDButton>
                ) : null}
              </MDBox>
            </Header>
          ) : (
            <Grid my={20}>
              <MDTypography
                variant="text"
                color="text"
                display="flex"
                justifyContent="center"
                mb={2}
              >
                No Companies found !
              </MDTypography>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
              ></Grid>
            </Grid>
          )}
        </>
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default PotentialPartnersAdminView;
