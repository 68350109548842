import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import Footer from "common/Footer";
import {
  Alert,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Snackbar,
  Stack,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MDInput from "components/MDInput";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import MDButton from "components/MDButton";
import { useFormik } from "formik";
import { uploadDocument } from "api_services/UserService";
import secureLocalStorage from "react-secure-storage";
import { GENERAL_CONSTS } from "utils/constants/general.constants";
import { useNavigate, useParams } from "react-router-dom";
import { getBannerById } from "api_services/BannerService";
import { updateBannerById } from "api_services/BannerService";
import { editBannerSchema } from "layouts/validationSchema/editBannerSchema";

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#044B99",
      darker: "#044B99",
    },
  },
});

function getCurrentDate() {
  let date = new Date();
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0");
  let yyyy = String(date.getFullYear());
  return yyyy + "-" + mm + "-" + dd;
}

function EditBanner() {
  const userToken = secureLocalStorage.getItem("custom-token");
  const currentDate = getCurrentDate();

  const { id } = useParams();
  const [saving, setSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [uploadFile, setUploadFile] = useState("");
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [bannerData, setBannerData] = useState({});
  const [positionState, setPositionState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = positionState;
  const handleAlertClose = () => {
    setPositionState({ ...positionState, open: false });
  };
  const navigate = useNavigate();

  async function getBannerData(id) {
    const response = await getBannerById(id, userToken);

    if (response._id !== null) {
      console.log("response", response);
      setIsLoading(false);
      setBannerData(response);
    } else {
      setIsLoading(true);
      //console.log(response);
    }
  }

  console.log("Banner data", bannerData);

  async function updateBanner(data) {
    setLoadingOpen(true);
    setSaving(true);

    // console.log("Banner data:", data);

    const response = await updateBannerById(id, data, userToken);
    if (response == "ok") {
      navigate("/banners");
    } else {
      setLoadingOpen(false);
      setSaving(false);
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
    }
  }

  async function uploadNewDocument(file) {
    if (file) {
      //console.log("file in downloads : ", file);
      setLoadingOpen(true);
      setSaving(true);
      const response = await uploadDocument(
        file,
        GENERAL_CONSTS.BANNERS_ROOT_FOLDER,
        userToken,
        values.title,
        null,
        null
      );
      if (response?.fileDownloadUri) {
        const downloadUri = response.fileDownloadUri;
        return downloadUri.substring(0, downloadUri.indexOf("?"));
      } else {
        setLoadingOpen(false);
        setSaving(false);
        setPositionState({ open: true, vertical: "top", horizontal: "center" });
        //console.log("file upload failed");
      }
    } else {
      return null;
    }
  }

  const {
    getRootProps: getRootFileProps,
    getInputProps: getInputFileProps,
    acceptedFiles,
  } = useDropzone({
    accept: [".doc", ".docx", "application/pdf", ".xls", ".xlsx", ".pptx"],
    onDrop: (acceptedFile) => {
      setImageUrl(URL.createObjectURL(acceptedFile[0]));
      setFieldValue("file", acceptedFile[0]);
      setUploadFile(acceptedFile[0]);
    },
  });

  const iconPreview = acceptedFiles.map(() => (
    <MDBox
      mt={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ width: { xs: "50%", sm: "30%", md: "15%" } }}
    >
      <img src={imageUrl} alt={imageUrl} width="100%" />
    </MDBox>
  ));

  const onSubmit = async () => {
    try {
      const bannerDownloadUri = await uploadNewDocument(uploadFile);
      const bannerObj = {
        tenantId: "FC",
        title: values.title,
        // description: values.description,
        url: values.url,
        cities: values.cities.length > 0 ? values.cities.split(",") : [],
        countries: values.countries,
        labels: [values.labels],
        // tags: values.tags.length > 0 ? values.tags.split(",") : [],
        // labels: values.labels.length > 0 ? values.labels.split(",") : [],
        priority: values.priority,
        expiryDate: Date.parse(values.expiryDate),
        dateToPublish: Date.parse(values.publishDate),
        bannerImage: bannerDownloadUri,
      };

      updateBanner(bannerObj);
    } catch (e) {
      setPositionState({ open: true, vertical: "top", horizontal: "center" });
      //console.log(e.message);
    }
  };

  const expiryDate = new Date(bannerData.expiryDate);
  const expiryyear = expiryDate.getFullYear();
  const expirymonth = expiryDate.getMonth() + 1;
  const expiryday = expiryDate.getDate();
  const formattedExpiryDate = `${expiryyear}-${
    expirymonth < 10 ? "0" + expirymonth : expirymonth
  }-${expiryday < 10 ? "0" + expiryday : expiryday}`;
  ////console.log(formattedExpirayDate);

  const publDate = new Date(bannerData.dateToPublish);
  const publyear = publDate.getFullYear();
  const publmonth = publDate.getMonth() + 1;
  const publday = publDate.getDate();
  const formattedPublDate = `${publyear}-${publmonth < 10 ? "0" + publmonth : publmonth}-${
    publday < 10 ? "0" + publday : publday
  }`;
  ////console.log(formattedEnlrDate);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: bannerData.title,
      // description: bannerData.description,
      url: bannerData.url,
      cities: bannerData.cities !== undefined ? bannerData.cities.join(", ") : "",
      countries: bannerData.countries !== undefined ? bannerData.countries : [],
      labels:
        bannerData.labels !== undefined && bannerData.labels.length > 0
          ? bannerData.labels[0]
          : "FreytConsol",
      // tags: bannerData.tags !== undefined ? bannerData.tags.join(", ") : "",
      // labels: bannerData.labels !== undefined ? bannerData.labels.join(", ") : "",
      priority: bannerData.priority,
      expiryDate: formattedExpiryDate,
      publishDate: formattedPublDate,
      file: null,
    },

    validationSchema: editBannerSchema,
    onSubmit,
  });

  useEffect(() => {
    getBannerData(id);
  }, []);

  //////////////////////////

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox />
      <Card sx={{ p: 3, mb: 5 }}>
        <MDBox>
          <Grid
            container
            pt={0}
            lineHeight={1.25}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} md={12} xl={12}>
              <MDTypography variant="h5" fontWeight="medium" color="black">
                Edit Banner
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        {isLoading ? (
          <Stack spacing={3}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "0.8rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}
            <MDBox display="flex">
              <Skeleton variant="circular" width={160} height={140} sx={{ mr: 5 }} />
              <Skeleton variant="rectangular" width={"100%"} height={140} />
            </MDBox>

            <Skeleton variant="rectangular" width={"100%"} height={20} />
            <Skeleton variant="rounded" width={"100%"} height={200} />
            <Skeleton variant="rectangular" width={"100%"} height={20} />
          </Stack>
        ) : (
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            component="form"
            role="form"
            onSubmit={handleSubmit}
          >
            <Grid item xs={12} md={12} xl={12} pt={5}>
              <MDBox
                sx={{
                  width: "100%",
                  height: "min(auto, 196px)",
                }}
              >
                <img
                  width="100%"
                  height="100%"
                  src={bannerData.bannerImage}
                  style={{ borderRadius: "17px", objectFit: "fit" }}
                />
              </MDBox>

              <MDBox mb={2} display="flex">
                <Grid xs={12} mt={3} md={6} xl={6}>
                  <MDBox pr={0.5}>
                    <MDInput
                      error={errors.title && touched.title && true}
                      focused={errors.title && touched.title && true}
                      label={errors.title && touched.title ? errors.title : "Title"}
                      type="text"
                      fullWidth
                      id="title"
                      onChange={handleChange}
                      value={values.title}
                      onBlur={handleBlur}
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} mt={3} md={6} xl={6}>
                  <MDBox pl={0.5}>
                    <MDInput
                      error={errors.priority && touched.priority && true}
                      focused={errors.priority && touched.priority && true}
                      label={errors.priority && touched.priority ? errors.priority : "Priority"}
                      type="number"
                      fullWidth
                      id="priority"
                      onChange={handleChange}
                      value={values.priority}
                      onBlur={handleBlur}
                    />
                  </MDBox>
                </Grid>
              </MDBox>

              {/* <MDBox mb={2}>
                <MDInput
                  error={errors.description && touched.description && true}
                  focused={errors.description && touched.description && true}
                  label={
                    errors.description && touched.description ? errors.description : "Description"
                  }
                  type="text"
                  fullWidth
                  id="description"
                  onChange={handleChange}
                  value={values.description}
                  onBlur={handleBlur}
                />
              </MDBox> */}

              <MDBox mb={2}>
                <MDInput
                  error={errors.url && touched.url && true}
                  focused={errors.url && touched.url && true}
                  label={errors.url && touched.url ? errors.url : "Marketing Link"}
                  type="text"
                  fullWidth
                  id="url"
                  onChange={handleChange}
                  value={values.url}
                  onBlur={handleBlur}
                />
              </MDBox>

              <MDBox mb={2}>
                <FormControl
                  fullWidth
                  error={errors.countries && touched.countries && true}
                  focused={errors.countries && touched.countries && true}
                >
                  <InputLabel id="demo-multiple-chip-label">
                    {errors.countries && touched.countries ? errors.countries : "Countries"}
                  </InputLabel>
                  <Select
                    error={errors.countries && touched.countries && true}
                    label={errors.countries && touched.countries ? errors.countries : "Countries"}
                    id="label"
                    labelId="demo-multiple-name-label"
                    multiple
                    fullWidth
                    {...getFieldProps("countries")}
                    value={values.countries}
                    renderValue={(selected) => (
                      <MDBox
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignContent: "center",
                          overflowX: "auto",
                          minHeight: 53,
                          gap: 1,
                          color: "white",
                        }}
                        py={1}
                      >
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              <MDTypography variant="button" color="black">
                                {value}
                              </MDTypography>
                            }
                          />
                        ))}
                      </MDBox>
                    )}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                    sx={{ minHeight: 42.4, borderColor: "red" }}
                  >
                    {GENERAL_CONSTS.COUNTRIES.map((item, idx) => (
                      <MenuItem
                        sx={{ my: 1, borderRadius: 50, width: "min-content", color: "info" }}
                        key={idx}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>

              <MDBox mb={2}>
                <MDInput
                  error={errors.cities && touched.cities && true}
                  focused={errors.cities && touched.cities && true}
                  label={
                    errors.cities && touched.cities
                      ? errors.cities
                      : "Cities - Comma separated list"
                  }
                  type="text"
                  fullWidth
                  id="cities"
                  onChange={handleChange}
                  value={values.cities}
                  onBlur={handleBlur}
                />
              </MDBox>

              {/* <MDBox mb={2}>
                <MDInput
                  error={errors.tags && touched.tags && true}
                  focused={errors.tags && touched.tags && true}
                  label={errors.tags && touched.tags ? errors.tags : "Tags - Comma separated list"}
                  type="text"
                  fullWidth
                  id="tags"
                  onChange={handleChange}
                  value={values.tags}
                  onBlur={handleBlur}
                />
              </MDBox>

              <MDBox mb={2}>
                <MDInput
                  error={errors.labels && touched.labels && true}
                  focused={errors.labels && touched.labels && true}
                  label={
                    errors.labels && touched.labels
                      ? errors.labels
                      : "Labels - Comma separated list"
                  }
                  type="text"
                  fullWidth
                  id="labels"
                  onChange={handleChange}
                  value={values.labels}
                  onBlur={handleBlur}
                />
              </MDBox> */}

              <MDBox mb={2} display="flex">
                <Grid xs={12} mt={3} md={6} xl={6}>
                  <MDBox pr={0.5}>
                    <MDInput
                      type={"date"}
                      fullWidth
                      error={errors.publishDate && touched.publishDate && true}
                      focused={
                        (errors.publishDate && touched.publishDate && true) ||
                        (values.publishDate && true)
                      }
                      label={
                        errors.publishDate && touched.publishDate
                          ? errors.publishDate
                          : "Date To Publish"
                      }
                      id="publishDate"
                      onChange={handleChange}
                      value={values.publishDate}
                      onBlur={handleBlur}
                    />
                  </MDBox>
                </Grid>

                <Grid xs={12} mt={3} md={6} xl={6}>
                  <MDBox pl={0.5}>
                    <MDInput
                      type={"date"}
                      fullWidth
                      error={errors.expiryDate && touched.expiryDate && true}
                      focused={
                        (errors.expiryDate && touched.expiryDate && true) ||
                        (values.expiryDate && true)
                      }
                      label={
                        errors.expiryDate && touched.expiryDate ? errors.expiryDate : "Expiry Date"
                      }
                      id="expiryDate"
                      onChange={handleChange}
                      value={values.expiryDate}
                      onBlur={handleBlur}
                    />
                  </MDBox>
                </Grid>
              </MDBox>
              <Grid item xs={12} md={12} xl={12} mb={1}>
                <MDBox mt={2}>
                  <ThemeProvider theme={theme}>
                    <FormControl>
                      <MDTypography textTransform="capitalize">
                        <span
                          style={{
                            color: "black",
                            fontSize: "0.65rem",
                            fontFamily: "Poppins",
                            fontWeight: "400",
                          }}
                        >
                          Type
                        </span>
                      </MDTypography>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="FreytConsol"
                        name="labels"
                        value={values.labels}
                        onChange={handleChange}
                      >
                        <MDBox display="flex">
                          <FormControlLabel
                            value="FreytConsol"
                            control={<Radio />}
                            label={
                              <MDTypography variant="button" color="text" fontWeight="regular">
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: "0.65rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                  }}
                                >
                                  Freyt Consol
                                </span>
                              </MDTypography>
                            }
                          />
                          <FormControlLabel
                            value="FreytInsure"
                            control={<Radio />}
                            label={
                              <MDTypography variant="button" color="text" fontWeight="regular">
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: "0.65rem",
                                    fontFamily: "Poppins",
                                    fontWeight: "400",
                                  }}
                                >
                                  Freyt Insure
                                </span>
                              </MDTypography>
                            }
                          />
                        </MDBox>
                      </RadioGroup>
                    </FormControl>
                  </ThemeProvider>
                </MDBox>
              </Grid>

              <MDBox mb={1}>
                <MDTypography
                  variant="button"
                  color="black"
                  fontWeight="regular"
                  textTransform="capitalize"
                  ml={1.7}
                >
                  Banner Image
                </MDTypography>
                {errors.file && touched.file && (
                  <MDBox pl={1.5}>
                    <MDTypography variant="button" color="error" sx={{ fontSize: 9.6 }}>
                      {errors.file}
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>

              <MDBox>
                <MDBox
                  {...getRootFileProps({ className: "dropzone" })}
                  variant="outlined"
                  color={errors.file && touched.file ? "error" : "text"}
                  height="100%"
                  sx={{
                    border: errors.file && touched.file ? "1.6px solid" : "1.6px dotted",
                    borderColor: "text",
                    borderRadius: "5px",
                  }}
                  py={3}
                  px={8}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <MDBox>
                    <input {...getInputFileProps()} />
                    <MDTypography color="text" sx={{ cursor: "pointer" }} variant="button">
                      Drop files here, or Click to select files
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox mt={0} display="flex" alignItems="center" justifyContent="center">
                  {errors.file && touched.file ? "" : iconPreview}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} xl={12} mb={2}>
              {/* <MDBox mx={0}>
                <MDBox mb={1} ml={1.7}>
                    <MDTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                      textTransform="capitalize"
                    >
                      Upload Icon
                    </MDTypography>
                    {errors.picture && touched.picture && (
                      <MDBox>
                        <MDTypography variant="button" color="error" sx={{ fontSize: 9.6 }}>
                          {errors.picture}
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>

                  <MDBox
                    {...getRootLogoProps({ className: "dropzone" })}
                    variant="outlined"
                    sx={{
                      border: errors.picture && touched.picture ? "1.6px solid" : "1.6px dotted",
                      borderColor: "text",
                      borderRadius: "5px",
                    }}
                    color={errors.picture && touched.picture ? "error" : "text"}
                    py={3}
                    px={5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MDBox>
                      <input {...getInputLogoProps()} />
                      <MDTypography color="text" sx={{ cursor: "pointer" }} variant="button">
                        Drop files here, or Click to select files
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  {errors.picture && touched.picture ? "" : fileAcceptedItems}
                </MDBox> */}

              <MDBox mx={0} mt={3} display="flex" justifyContent="end">
                <MDButton type="submit" to="" size="medium" color="primary">
                  Update Banner
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        )}
        <Divider />

        {/* {files.length !== 0 ? (
          <Grid container px={0} display="flex" justifyContent="space-between">
            {Documents}
          </Grid>
        ) : (
          <Grid my={20}>
            <MDTypography variant="text" color="text" display="flex" justifyContent="center" mb={2}>
              No Downloads found !
            </MDTypography>
          </Grid>
        )} */}
      </Card>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          Banner update failed!
        </Alert>
      </Snackbar>

      {/* Dialog Popup //////////////////////////////////////////// */}

      <MDBox sx={{ overflowY: "visible" }}>
        <Dialog
          open={loadingOpen}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflowY: "visible" }}
        >
          <MDBox mb={1} mt={2} sx={{ mx: { xs: 5, md: 10 } }} textAlign="center">
            <MDTypography display="block" variant="button" color="primaryDark">
              Creating new banner ...
            </MDTypography>
            {saving && <CircularProgress sx={{ mt: 2 }} />}
          </MDBox>
        </Dialog>
      </MDBox>

      {/* //////////////////////////////////////////// */}

      <Footer />
    </DashboardLayout>
  );
}

export default EditBanner;
